$(document).ready(function () {

    /*--Overflow scroll glitch fix---*/

    let div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    document.body.append(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    let moveElement = (element, target, screenSize, append = true, after = false) => {
        if (screen.width < screenSize) {
            if (after) {
                for (let i = 0; i < $(element).length; i++) {
                    $(target).eq(i).after($(element).eq($(target).length === 1 ? 0 : i))
                }
            } else {
                if (append) {
                    for (let i = 0; i < $(element).length; i++) {
                        $(element).eq(i).appendTo($(target).eq($(target).length === 1 ? 0 : i))
                    }
                } else {
                    for (let i = 0; i < $(element).length; i++) {
                        $(element).eq(i).prependTo($(target).eq($(target).length === 1 ? 0 : i))
                    }
                }
            }
        }
    }

    moveElement('.main-nav', '.mobile-menu', 991);


    $(document).on('click', '.js-open-mobile-menu', function (e) {
        $('.mobile-menu').addClass('opened');
        $('body').css({
            'padding-right': scrollWidth,
            'overflow-y': 'hidden',
        });
    })

    let closeMobileMenu = () => {
        $('.mobile-menu').removeClass('opened');
        $('body').css({
            'padding-right': 0,
            'overflow-y': 'auto',
        });
    }

    $(document).on('click', '.js-close-mobile-menu', function (e) {
       closeMobileMenu();
    });

    let scrollTo = (target) => {
        if ($('.mobile-menu').hasClass('opened')) {
            closeMobileMenu();
        }
        $([document.documentElement, document.body]).animate({
            scrollTop: target.offset().top
        }, 1000);
    }

    $(document).on('click', '.js-scroll-to', function (e) {
        let vThis = $(e.target);
        let target = vThis.attr('data-scroll');
        scrollTo($(target));
    })


});


